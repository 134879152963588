import {computed, Pipe, PipeTransform} from '@angular/core';

import {DatePipe} from '@angular/common';
import {UserLoggedService} from '../../core/services/userLogged.service';

@Pipe({
  name: 'LocalDate',
})
export class LocalDatePipe extends DatePipe implements PipeTransform {
  private readonly dateFormat = computed(() => this.getDateFormat(this.userLoggedService.connectedUser$()?.dateFormat || 'ja-JP'));

  constructor(private readonly userLoggedService: UserLoggedService) {
    super('en');
  }

  transform(date: Date): any {
    return super.transform(date, this.dateFormat());
  }

  private getDateFormat(userDateFormat: string) {
    switch (userDateFormat) {
      case 'en-US':
        return 'MM/dd/yyyy, h:mm:ss a';
      case 'eu-EU':
        return 'dd/MM/yyyy, HH:mm:ss';
      default:
        return 'yyyy/MM/dd, HH:mm:ss';
    }
  }
}
