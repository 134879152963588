import {HttpClient} from '@angular/common/http';
import {computed, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Language} from 'bs-components';
import {UserLoggedService} from './userLogged.service';
import {MessageHandlerService} from './messageHandler.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {

  public currentLanguage$: Signal<Language> = computed(() => {
    const languages = this._languages$();
    return languages
        .find((language) => this.userLoggedService.connectedUser$().language === language.key)
      ?? languages[0];
  });

  private readonly serviceUrl: string = `${environment.babelApiUrl}/languages`;
  private readonly _languages$: WritableSignal<Language[]> = signal([]);


  constructor(private readonly http: HttpClient,
              private readonly sanitizer: DomSanitizer,
              private readonly userLoggedService: UserLoggedService,
              private readonly messageService: MessageHandlerService,
              private readonly translate: TranslateService) {
  }

  public init(): Observable<any> {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en-GB');
    this.translate.addLangs(['en-GB', 'en-US', 'es-ES', 'fr-FR', 'ru-RU']);

    const language = this.getUserLanguage();
    return forkJoin([
      this.getAll(),
      this.update(language)
    ]);
  }


  public update(language: string): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}/users/customer/updateLanguage/${language}`, undefined).pipe(
      switchMap(() => {
        this.userLoggedService.reloadCurrentUser();
        return this.translate.use(language);
      }),
      catchError(this.messageService.handleError<any>('update langage'))
    );
  }

  public get languages$(): Signal<Language[]> {
    return this._languages$.asReadonly();
  }

  private isSupported(language: string): boolean {
    return /es-ES|en-GB|en-US|fr-FR|ru-RU|ar-SA|de-DE/.test(language);
  }

  private getAll(): Observable<Language[]> {
    return this.http.get<Language[]>(`${this.serviceUrl}/apps/et-adm`).pipe(
      map((langs: Language[]) => {
        return langs.map((l) => {
          l.flagIcon = this.sanitizer.bypassSecurityTrustHtml(l.flagSvg);
          return l;
        });
      }),
      tap((langs: Language[]) => {
        this._languages$.set(langs);
      }),
      catchError(() => {
        console.error('Loading languages failed');
        return of([]);
      })
    );
  }

  private getUserLanguage() {
    const browserLang = this.translate.getBrowserLang();
    // get the language in which the user was using the app lately
    const userLang = this.userLoggedService.connectedUser$().language;

    if (this.isSupported(userLang)) {
      return userLang;
    } else if (this.isSupported(browserLang)) {
      return browserLang;
    } else {
      return 'en-GB';
    }
  }
}
