<div class="row row-cols-1 row-cols-md-6 mx-0 h-100">
    <div class="col m-auto">
        <div class="card">
            <div class="card-header">
                <span class="card-title">{{ 'GLOBAL.GLOBAL.SETTINGS' | translate
                    }}</span>
            </div>

            <div class="card-body">
                <div class="languageSelect">
                    <i class="fas fa-earth-americas fa-lg fa-2x"></i>
                    <span class="ps-1">{{ 'GLOBAL.GLOBAL.LANGUAGE' | translate
                        }}</span>
                    <div class="dropdown">
                        <!-- Current language display -->
                        <a class="dropdown-toggle" href="#"
                            id="dropdownLanguage" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span>
                                <span>
                                    <div class="flag-img"
                                        [innerHTML]="currentLanguage$()?.flagIcon"></div>
                                    <span
                                        class="flag-text">{{currentLanguage$()?.name}}</span>
                                </span>
                            </span>
                        </a>

                        <!-- dropdown menu to display other options to let the user choose among other available languages -->
                        <div class="dropdown-menu"
                            aria-labelledby="dropdownLanguage">
                            <h6 class="dropdown-header">{{'GLOBAL.GLOBAL.CHOOSE_LANGUAGE'
                                | translate }}</h6>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [ngClass]="{'active
                                disabled':translate.currentLang ===
                                language.tag}"
                               *ngFor="let language of languages$()"
                                [id]="'dropdownLanguageOption_' + language.key"
                                (click)="changeLanguage(language.key)">
                                <div class="flag-img"
                                    [innerHTML]="language.flagIcon"></div>
                                <span class="flag-text">{{language.name}}</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="dateSelect">
                    <i class="fas fa-calendar-day fa-lg fa-2x"></i>
                    <span class="ps-1">{{ 'GLOBAL.GLOBAL.DATE_FORMAT' |
                        translate }}</span>
                    <div class="dropdown">
                        <!-- Current date format display -->
                        <a class="dropdown-toggle" href="#"
                            id="dropdownDateFormat" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span *ngFor="let format of dateFormat">
                                <span *ngIf="format.tag==actualFormat$()">
                                    <img class="flag-img"
                                        src="/assets/img/flags/{{format.tag}}.svg" />
                                    <span class="flag-text">{{format.name}}</span>
                                </span>
                            </span>
                        </a>

                        <!-- dropdown menu to display other options to let the user choose among other available format of date -->
                        <div class="dropdown-menu"
                            aria-labelledby="dropdownDate">
                            <h6 class="dropdown-header">{{'GLOBAL.GLOBAL.CHOOSE_DATE_FORMAT'
                                | translate}}</h6>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" [ngClass]="{'active
                                disabled':actualFormat$() === format.tag}"
                                *ngFor="let format of dateFormat"
                                (click)="changeDateFormat(format.tag)">
                                <img
                                    src="/assets/img/flags/{{format.tag}}.svg" />
                                <span class="flag-text">{{format.name}}
                                    <span class="ps-1">
                                        {{format.field}}
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
