import {Component, Input} from '@angular/core';
import {PageHeaderAction} from '../page-header-actions/page-header-actions.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() public backButton: boolean = true;
  @Input() public backButtonParams: any = {};
  @Input() public backButtonUrl: string = '';
  @Input() public pageTitle: string = '';
  @Input() public statusLabel: string = '';
  @Input() public statusColor: string = '';
  @Input() public leftStatusCallback: Function;
  @Input() public rightStatusCallback: Function;
  @Input() public actions: PageHeaderAction[] = [];

}
