import { Component, HostBinding, Input } from "@angular/core";
import { camelCase } from "lodash";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent {
  @Input()
  public item!: MenuItem;

  @HostBinding("id")
  get id(): string {
    return this.item.id;
  }
}

export class MenuItem {
  id: string;
  icon: string;
  label: string;
  url?: string;
  tooltip?: string;
  order?: number;
  exactPath?: boolean;
  cssClasses?: string;
  counters?: MenuItemCounter[];
  callback?: () => any;

  static getId(id: string) {
    return `${camelCase(id)}MenuButton`;
  }
}

export const rejectedTiresMenuItem: MenuItem = {
  id: MenuItem.getId("rejectedTires"),
  label: "WORKFLOW.GLOBAL.REJECTED",
  icon: "fas fa-2x fa-trash-alt",
  url: "/workflow/rejected-tires",
};

export const doubleSnTiresMenuItem: MenuItem = {
  id: MenuItem.getId("doubleSnTires"),
  label: "WORKFLOW.GLOBAL.DOUBLE_SN",
  icon: "fas fa-2x fa-clone",
  url: "/workflow/double-sn-tires",
};

export const damageReportsMenuItem: MenuItem = {
  id: MenuItem.getId("damageReports"),
  label: "WORKFLOW.GLOBAL.DAMAGE_REPORTS",
  icon: "fas fa-2x fa-exclamation-triangle",
  url: "/workflow/damage-reports",
  counters: [{ color: "warning", value: 0 }],
};

export const myBatchesMenuItem: MenuItem = {
  id: MenuItem.getId("myBatches"),
  label: "WORKFLOW.GLOBAL.MY_BATCHES",
  icon: "fas fa-2x fa-database",
  url: "/workflow/my-batches",
};

export const myTiresMenuItem: MenuItem = {
  id: MenuItem.getId("myTires"),
  label: "WORKFLOW.GLOBAL.MY_TIRES",
  icon: "fas fa-2x fa-circle-dot",
  url: "/workflow/my-tires",
};
export const dashboardMenuItem: MenuItem = {
  id: MenuItem.getId("dashboard"),
  label: "WORKFLOW.GLOBAL.DASHBOARD",
  icon: "fas fa-2x fa-gauge-high",
  url: "/workflow/dashboard",
};
export const resetDataMenuItem: MenuItem = {
  id: MenuItem.getId("resetData"),
  label: "WORKFLOW.GLOBAL.RESET_DATA",
  icon: "fas fa-2x fa-rotate-right",
};
export const newContainerMenuItem: MenuItem = {
  id: MenuItem.getId("newContainer"),
  label: "WORKFLOW.GLOBAL.NEW_CONTAINER",
  icon: "fas fa-2x fa-plus-square",
};
export const newPreparationMenuItem: MenuItem = {
  id: MenuItem.getId("newPreparation"),
  label: "Add new preparation",
  icon: "fas fa-2x fa-cart-plus",
};

export const newNotificationMenuItem: MenuItem = {
  id: MenuItem.getId("newNotification"),
  label: "New Notification",
  icon: "fas fa-2x fa-bell",
};

export interface MenuItemCounter {
  color: string;
  value: number;
}
