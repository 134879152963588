import {ChangeDetectionStrategy, Component, OnInit, signal, Signal, WritableSignal} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguagesService} from '../../../core/services/languages.service';
import {UserLoggedService} from '../../../core/services/userLogged.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Language} from 'bs-components';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SettingsComponent implements OnInit {
  public dateFormat: any[] = [
    {name: 'English-US', tag: 'en-US', field: '(M/DD/YYYY)'},
    {name: 'European', tag: 'eu-EU', field: '(DD/MM/YYYY)'},
    {name: 'Japanese', tag: 'ja-JP', field: '(YYYY/M/DD)'},
  ];
  public actualFormat$: WritableSignal<string> = signal('');

  public readonly languages$: Signal<Language[]> = this.langService.languages$;
  public readonly currentLanguage$: Signal<Language> = this.langService.currentLanguage$;

  constructor(
    public readonly translate: TranslateService,
    private readonly userLogged: UserLoggedService,
    private readonly langService: LanguagesService
  ) {
  }

  ngOnInit(): void {
    this.actualFormat$.set(this.userLogged.getDateFormat());
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.langService.update(language).subscribe();
  }

  changeDateFormat(format: string) {
    this.userLogged.updateDateFormat(format).subscribe();
    this.actualFormat$.set(format);
  }
}
