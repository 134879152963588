<app-easy-modal
  [modalTitle]="'COMMON.COMMON.DELETE' | translate"
  [overlayClose]="true"
  [open]="open"
  (close)="closeModal()">
  <div class="confirmation-body">
    {{ 'MODELS.BATCHES.CONFIRMATIONDELETE' | translate }}
  </div>
  <div class="confirmation-footer" footer>
    <bs-button
      [label]="'COMMON.COMMON.CANCEL' | translate"
      (click)="closeModal()"></bs-button>
    <bs-button
      color="danger"
      buttonId="ConfirmDelete"
      [label]="'COMMON.COMMON.DELETE' | translate"
      icon="fas fa-trash-alt"
      (click)="confirmDelete()"></bs-button>
  </div>
</app-easy-modal>
