<div class="menu-item" [routerLink]="item.url ? item.url : null"
     routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
    item.exactPath}"
     (click)="item.callback ? item.callback() : null"
     [ngClass]="item.cssClasses" [title]="(item.tooltip ?? item.label) |
    translate">
    <div class="menu-item-icon">
        <i [ngClass]="item.icon"></i>
    </div>
    <div class="menu-item-text d-none d-lg-block">{{ item.label | translate }}</div>
    <div class="badges">
        <ng-container *ngFor="let counter of item.counters">
            <div *ngIf="counter.value> 0" class="badge"
                 [style.background-color]="counter.color === 'warning' ? '' :
                counter.color"
                 [ngClass]="counter.color">
                <span>{{ counter.value }}</span>
            </div>
        </ng-container>
    </div>
</div>
